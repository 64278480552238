/// Menu
import Metismenu from "metismenujs";
import React, {Component, useContext, useEffect, useState} from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import {Link} from "react-router-dom";

import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import {ThemeContext} from "../../config/context/ThemeContext";
import {hasAValue} from "../../utils/SharedFunctions";
import {useSelector} from "react-redux";


class MM extends Component {
    componentDidMount() {
        this.$el = this.el;
        this.mm = new Metismenu(this.$el);
    }

    componentWillUnmount() {
    }

    render() {
        return (
            <div className="mm-wrapper">
                <ul className="metismenu" ref={(el) => (this.el = el)}>
                    {this.props.children}
                </ul>
            </div>
        );
    }
}

const SideBar = () => {
    const {
        iconHover,
        sidebarposition,
        headerposition,
        sidebarLayout,
    } = useContext(ThemeContext);

    const userData = useSelector( state => state.userData );

    useEffect(() => {
        var btn = document.querySelector(".nav-control");
        var aaa = document.querySelector("#main-wrapper");

        function toggleFunc() {
            return aaa.classList.toggle("menu-toggle");
        }

        btn.addEventListener("click", toggleFunc);

        //sidebar icon Heart blast
        var handleheartBlast = document.querySelector('.heart');

        function heartBlast() {
            return handleheartBlast.classList.toggle("heart-blast");
        }

        handleheartBlast.addEventListener('click', heartBlast);

    }, []);
    //For scroll
    const [hideOnScroll, setHideOnScroll] = useState(true)
    useScrollPosition(
        ({prevPos, currPos}) => {
            const isShow = currPos.y > prevPos.y
            if (isShow !== hideOnScroll) setHideOnScroll(isShow)
        },
        [hideOnScroll]
    )
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];
    /// Active menu

    let deshBoard = [
        "",
        "dashboard-dark",
        "guest-list",
        "guest-details",
        "concierge-list",
        "room-list",
        "task",
    ];

    return (
        <div
            className={`dlabnav ${iconHover} ${
                sidebarposition.value === "fixed" &&
                sidebarLayout.value === "horizontal" &&
                headerposition.value === "static"
                    ? hideOnScroll > 120
                        ? "fixed"
                        : ""
                    : ""
            }`}
        >
            <PerfectScrollbar className="dlabnav-scroll">
                <MM className="metismenu" id="menu">

                    <li className={`${path.includes("dashboard") ? "mm-active" : ""}`}>
                        <Link to="/dashboard">
                            <i className="fas fa-home"></i>
                            <span className="nav-text">Dashboard</span>
                        </Link>
                    </li>

                    { (hasAValue(userData) && userData.user_type.toString().includes("admin")) &&   <li className={`${path.includes("users") ? "mm-active" : ""}`}>
                        <Link to="/users">
                            <i className="fas flaticon-381-user-7"></i>
                            <span className="nav-text">Users</span>
                        </Link>
                    </li>}

                    <li className={`${path.includes("vacancies") ? "mm-active" : ""}`}>
                        <Link to="/vacancies">
                            <i className="fas flaticon-381-tab"></i>
                            <span className="nav-text">Vacancies</span>
                        </Link>
                    </li>

                    <li className={`${path.includes("from-joppboard-to-cockpit") ? "mm-active" : ""}`}>
                        <Link to="/from-joppboard-to-cockpit">
                            <i className="fas flaticon-381-tab"></i>
                            <span className="nav-text">From Joppboard to Cockpit</span>
                        </Link>
                    </li>

                    <li className={`${path.includes("received-profiles") ? "mm-active" : ""}`}>
                        <Link to="/received-profiles">
                            <i className="fas flaticon-381-folder-9"></i>
                            <span className="nav-text">Received profiles</span>
                        </Link>
                    </li>

                    {/*<li className={`${path.includes("candidates") ? "mm-active" : ""}`}>*/}
                    {/*    <Link to="/candidates">*/}
                    {/*        <i className="fas flaticon-381-user-9"></i>*/}
                    {/*        <span className="nav-text">Candidates</span>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}

                    { (hasAValue(userData) && userData.user_type.toString().includes("admin")) &&   <li className={`${path.includes("auth-keys") ? "mm-active" : ""}`}>
                        <Link to="/auth-keys">
                            <i className="fas flaticon-381-lock-3"></i>
                            <span className="nav-text">Auth keys</span>
                        </Link>
                    </li>}


                </MM>

                <div className="copyright">
                    <p className="fs-14 fw-bold text-center">Made with <span className="heart"></span> by <a
                        href="https://it-plus24.com" target="_blank" rel="noreferrer">IT-plus24</a></p>
                </div>
            </PerfectScrollbar>
        </div>
    );
};

export default SideBar;

import axios from "axios";
import {getDataLocalStorage} from "../utils/LocalStorageFunctions";
import {appLocalStoargeValues} from "../config/Constants";
import {hasAValue} from "../utils/SharedFunctions";
import {store} from "../redux/store";

// const dotenv = require("dotenv");
// dotenv.config();
export const apiGet = async (route, options = {}, headersReceived = (headers) => {
}) => {
    const stateRedux = store.getState();
    let user_data = stateRedux.userData;
    let accessToken = "test"
    if (hasAValue(user_data)) {
        accessToken = user_data.access_token
    }

    return axios.get(route, {
        headers: {
            'Authorization': accessToken,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            let err = JSON.parse(JSON.stringify(error));
            console.log("Error:  ", route)
            throw error;
        });
};

export const apiDelete = async (route, data = {}) => {
    const stateRedux = store.getState();
    let user_data = stateRedux.userData;
    let accessToken = "test"
    if (hasAValue(user_data)) {
        accessToken = user_data.access_token
    }
    return axios.post(route, data, {
        headers: {
            'Authorization': accessToken,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            console.log("Error:  ", route)
            throw error;
        });
};

export const apiPut = async (route, data, options = {}) => {
    const stateRedux = store.getState();
    let user_data = stateRedux.userData;
    let accessToken = "test"
    if (hasAValue(user_data)) {
        accessToken = user_data.access_token
    }
    return axios.put(route, data, {
        headers: {
            'Authorization': accessToken,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            console.log("Error:  ", route)
            throw error;
        });
};

export const apiPost = async (route, data, options = {}) => {
    const stateRedux = store.getState();
    let user_data = stateRedux.userData;
    let accessToken = "test"
    if (hasAValue(user_data)) {
        accessToken = user_data.access_token
    }
    return axios.post(route, data, {
        headers: {
            'Authorization': accessToken,
            'Content-Type': 'application/json',
            // 'Access-Control-Allow-Origin': '*',
        }
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            let err = JSON.parse(JSON.stringify(error));
            console.log("Error:  ", route)
            throw error;
        });
};

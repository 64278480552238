import React, {useEffect, useState} from 'react';
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';
import '../../components/FilteringTable/filtering.css';

import {toast} from "react-toastify";


import {jobs_public_col} from "../../components/FilteringTable/Columns";

import {GlobalFilter} from "../../components/FilteringTable/GlobalFilter";
import {toastOptions} from "../../config/Constants";
import {getAllJobsPublicCall, uploadFileCall} from "../../Api/ApiCalls";
import {Modal} from "react-bootstrap";
import {hasAValue} from "../../utils/SharedFunctions";
import logo from "../../style/images/logo-full.png";


export default function VacanciesPublic() {
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])

    const [item, setItem] = useState(undefined)
    const [index, setIndex] = useState(undefined)
    const [image, setImage] = useState(undefined)
    const [showModal, setShowModal] = useState(false)
    const [showEdit, setShowEdit] = useState(false)


    useEffect(() => {
        setColumns(jobs_public_col)
        getVacancies()
    }, [])

    useEffect(() => {
        setItem(data[index])
    }, [data])


    const tableInstance = useTable({
        columns,
        data,
        initialState: {pageIndex: 0}
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance


    const {globalFilter, pageIndex} = state


    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Vacancies</h4>
                    {/*<a className="btn btn-secondary" onClick={() => openModalAdd()}>+ Add new domain</a>*/}

                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
                        <table {...getTableProps()} className="table dataTable display">
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span className="ml-1">
                                                {column.isSorted ? (
                                                        column.isSortedDesc ?
                                                            <i className="fa fa-arrow-down ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                            :
                                                            <i className="fa fa-arrow-up ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                    )
                                                    :
                                                    (<i className="fa fa-sort ms-2 fs-14"
                                                        style={{opacity: '0.3'}}/>)}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>

                            {page.map((row) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()}
                                                       className={"cursor-pointer"}
                                                       onClick={() => openModalEdit(row)}> {cell.render('Cell')} </td>
                                        })}

                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        {/* This is only for footer if u require */}
                        <div className="d-flex justify-content-between">
							<span>
								Page{' '}
                                <strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
                            <span className="table-index">
								Go to page : {' '}
                                <input type="number"
                                       className="ml-2"
                                       defaultValue={pageIndex + 1}
                                       onChange={e => {
                                           const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                           gotoPage(pageNumber)
                                       }}
                                />
							</span>
                        </div>
                        <div className="text-center mb-3">
                            <div className="filter-pagination  mt-3">
                                <button className=" previous-button" onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}>{'<<'}</button>

                                <button className="previous-button" onClick={() => previousPage()}
                                        disabled={!canPreviousPage}>
                                    Previous
                                </button>
                                <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                    Next
                                </button>
                                <button className=" next-button" onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}>{'>>'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal className="modal fade" size={"xl"} show={showModal} onHide={setShowModal}>
                <div className="" role="document">
                    <div className="">
                        {hasAValue(item) && <form>
                            <div className="modal-header">
                                {hasAValue(item) && <h4 className="modal-title fs-20">Vacancy {item.id_jboard_job}</h4>}
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}
                                        data-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">

                                {/*Order info*/}
                                <div className="card border-top shadow">
                                    <div className="card-header border-0 pb-0">
                                        <h2 className="card-title">Vacancy overview</h2>
                                    </div>
                                    <div className="card-body pb-0">
                                        <ul className="list-group list-group-flush">

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Vacancy ID</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.id)) && (item.id)}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Job title</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.jobTitle)) && (item.jobTitle)}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Job summary</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.jobSummary)) && (item.jobSummary)}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Function description</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.functionDescription)) && (item.functionDescription)}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>About company job</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.aboutCompanyJob)) && (item.aboutCompanyJob)}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Job requirements</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.jobRequirements)) && (item.jobRequirements)}</span>
                                            </li>
                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Job offer</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.jobOffer)) && (item.jobOffer)}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Job additional info</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.jobAdditionalInfo)) && (item.jobAdditionalInfo)}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Job min hours</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.jobMinHours)) && (item.jobMinHours)}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Job max hours</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.jobMaxHours)) && (item.jobMaxHours)}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Job min salary</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.jobMinSalary)) && (item.jobMinSalary)}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Job max salary</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.jobMaxSalary)) && (item.jobMaxSalary)}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Featured</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.featured)) && (item.featured? "YES": "NO")}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Logo</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.logo)) && (item.logo)}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Location</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.location)) && (item.location)}</span>
                                            </li>


                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Company</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.company)) && (item.company)}</span>
                                            </li>


                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Posted at</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.postedAt)) && (item.postedAt)}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Origin</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.origin)) && (item.origin)}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Background</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.background)) && (item.background)}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Job url</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.job_url)) && (item.job_url)}</span>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                            </div>

                            <div className="modal-footer">

                                <button type="button" onClick={() => setShowModal(false)}
                                        className="btn btn-warning">Close
                                </button>
                            </div>
                        </form>}

                    </div>
                </div>
            </Modal>
        </>
    )

    function openModalEdit(item) {
        setItem(item.original)
        setIndex(parseInt(item.id))
        setShowModal(true)
        setShowEdit(true)

    }


    function uploadFile(is_logo) {
        const formData = new FormData();
        formData.append('id_vacancy', item.id_vacancy);
        formData.append('is_logo', is_logo);
        formData.append('file', image);
        uploadFileCall(formData).then(success => {
            toast.success(success.data.message, toastOptions);
            getVacancies()
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }

    function getVacancies() {
        getAllJobsPublicCall().then(vacancies => {
            console.log(vacancies)
            setData(vacancies)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }
}

import {ColumnFilter} from './ColumnFilter';
import {
    getCockpitResponseError,
    getCockpitStatus,
    getReceivedProfilesError, getReceivedProfilesErrorMessage, getReceivedProfilesType,
    hasAValue
} from "../../utils/SharedFunctions";
import ReceivedProfiles from "../../pages/internalPages/ReceivedProfiles";


export const COLUMNS = [
    {
        Header: 'Id',
        Footer: 'Id',
        accessor: 'id',
        Filter: ColumnFilter,
        //disableFilters: true,
    },
    {
        Header: 'Id vacancy',
        Footer: 'Id vacancy',
        accessor: 'id_vacancy',
        Filter: ColumnFilter,
    },
    {
        Header: 'Content',
        Footer: 'Content',
        accessor: 'content',
        Filter: ColumnFilter,
    },
    {
        Header: 'Created at',
        Footer: 'Created at',
        accessor: 'created_at',
        Filter: ColumnFilter,
    },
    {
        Header: 'Updated at',
        Footer: 'Updated at',
        accessor: 'updated_at',
        Filter: ColumnFilter,
    },
    {
        Header: 'Origin',
        Footer: 'Origin',
        accessor: 'origin',
        Filter: ColumnFilter,
    },
    {
        Header: 'Status',
        Footer: 'Status',
        accessor: 'status',
        Filter: ColumnFilter,
    },
    {
        Header: 'Status',
        Footer: 'Status',
        accessor: 'status',
        Filter: ColumnFilter,
    },
    {
        Header: 'Channel',
        Footer: 'Channel',
        accessor: 'channel',
        Filter: ColumnFilter,
    },
    {
        Header: 'Language',
        Footer: 'Language',
        accessor: 'language',
        Filter: ColumnFilter,
    },
]




export const jobs_public_col = [
    {
        Header: 'Id',
        Footer: 'Id',
        accessor: 'id',
        Filter: ColumnFilter,
        //disableFilters: true,
    },
    {
        Header: 'Job title',
        Footer: 'Job title',
        accessor: 'jobTitle',
        Filter: ColumnFilter,
    },
    {
        Header: 'Job summary',
        Footer: 'Job summary',
        accessor: 'jobSummary',
        Filter: ColumnFilter,
    },
    {
        Header: 'Posted at',
        Footer: 'Posted at',
        accessor: 'postedAt',
        Filter: ColumnFilter,
    },
    {
        Header: 'Origin',
        Footer: 'Origin',
        accessor: 'origin',
        Filter: ColumnFilter,
    },
]

// applications_col
export const applications_col = [
    {
        Header: 'Email',
        Footer: 'Email',
        accessor: 'email',
        Filter: ColumnFilter,
    },
    {
        Header: 'Created',
        Footer: 'Created',
        accessor: 'created_at',
        Filter: ColumnFilter,
    },
    {
        Header: 'Updated',
        Footer: 'Updated',
        accessor: 'updated_at',
        Filter: ColumnFilter,
    },

    {
        Header: 'Job Id',
        Footer: 'Job Id',
        accessor: 'jboard_job_id',
        Filter: ColumnFilter,
    },
    {
        Header: 'Id Application (CP)',
        Footer: 'Id Application (CP)',
        accessor: 'cockpit_application_id',
        Filter: ColumnFilter,
    },
    {
        Header: 'Cockpit status',
        Footer: 'Cockpit status',
        accessor: 'cockpit_status',
        Cell: ({ value }) => {return getCockpitStatus(value)},
        Filter: ColumnFilter,
    },
    {
        Header: 'Cockpit response',
        Footer: 'Cockpit response',
        accessor: 'cockpit_response',
        Cell: ({ value }) => {return getCockpitResponseError(value)},
        Filter: ColumnFilter,
    },

]

// candidates_col
export const candidates_col = [
    {
        Header: 'Email',
        Footer: 'Email',
        accessor: 'email',
        Filter: ColumnFilter,
    },
    {
        Header: 'Created',
        Footer: 'Created',
        accessor: 'created_at',
        Filter: ColumnFilter,
    },
    {
        Header: 'Updated',
        Footer: 'Updated',
        accessor: 'updated_at',
        Filter: ColumnFilter,
    },
    {
        Header: 'Id Job seeker (JB)',
        Footer: 'Id Job seeker (JB)',
        accessor: 'id_jboard_job_seeker',
        Filter: ColumnFilter,
    },
    {
        Header: 'Id Candidate (CP)',
        Footer: 'Id Candidate (CP)',
        accessor: 'cockpit_candidate_id',
        Filter: ColumnFilter,
    },
    {
        Header: 'Cockpit status',
        Footer: 'Cockpit status',
        accessor: 'cockpit_status',
        Cell: ({ value }) => {return getCockpitStatus(value)},
        Filter: ColumnFilter,
    },
    {
        Header: 'Cockpit response',
        Footer: 'Cockpit response',
        accessor: 'cockpit_response',
        Cell: ({ value }) => {return getCockpitResponseError(value)},
        Filter: ColumnFilter,
    },

]

// jobs_col
export const jobs_col = [
    {
        Header: 'Title',
        Footer: 'Title',
        accessor: 'jobName',
        Filter: ColumnFilter,
    },
    {
        Header: 'Created',
        Footer: 'Created',
        accessor: 'created_at',
        Filter: ColumnFilter,
    },
    {
        Header: 'Updated',
        Footer: 'Updated',
        accessor: 'updated_at',
        Filter: ColumnFilter,
    },
    {
        Header: 'Id Job (JB)',
        Footer: 'Id Job (JB)',
        accessor: 'id_jboard_job',
        Filter: ColumnFilter,
    },
    {
        Header: 'Id Job request (CP)',
        Footer: 'Id Job request (CP)',
        accessor: 'cockpit_job_request_id',
        Filter: ColumnFilter,
    },
    {
        Header: 'Id Publication (JB)',
        Footer: 'Id Publication (JB)',
        accessor: 'cockpit_publication_id',
        Filter: ColumnFilter,
    },
    {
        Header: 'Cockpit status',
        Footer: 'Cockpit status',
        accessor: 'cockpit_status',
        Cell: ({ value }) => {return getCockpitStatus(value)},
        Filter: ColumnFilter,
    },
    {
        Header: 'Cockpit response',
        Footer: 'Cockpit response',
        accessor: 'cockpit_response',
        Cell: ({ value }) => {return getCockpitResponseError(value)},
        Filter: ColumnFilter,
    },

]


// employers_col
export const employers_col = [
    {
        Header: 'Name',
        Footer: 'Name',
        accessor: 'companyName',
        Filter: ColumnFilter,
    },
    {
        Header: 'Created',
        Footer: 'Created',
        accessor: 'created_at',
        Filter: ColumnFilter,
    },
    {
        Header: 'Updated',
        Footer: 'Updated',
        accessor: 'updated_at',
        Filter: ColumnFilter,
    },
    {
        Header: 'Id Employer (JB)',
        Footer: 'Id Employer (JB)',
        accessor: 'id_jboard_employer',
        Filter: ColumnFilter,
    },
    {
        Header: 'Id Employer (CP)',
        Footer: 'Id Employer (CP)',
        accessor: 'cockpit_company_id',
        Filter: ColumnFilter,
    },
    {
        Header: 'Id Contact person (CP)',
        Footer: 'Id Contact person (CP)',
        accessor: 'cockpit_contact_person_id',
        Filter: ColumnFilter,
    },

    {
        Header: 'Cockpit status',
        Footer: 'Cockpit status',
        accessor: 'cockpit_status',
        Cell: ({ value }) => {return getCockpitStatus(value)},
        Filter: ColumnFilter,
    },
    {
        Header: 'Cockpit response',
        Footer: 'Cockpit response',
        accessor: 'cockpit_response',
        Cell: ({ value }) => {return getCockpitResponseError(value)},
        Filter: ColumnFilter,
    },

]

export const user_col = [
    {
        Header: 'Id',
        Footer: 'Id',
        accessor: 'id',
        Filter: ColumnFilter,
        //disableFilters: true,
    },
    {
        Header: 'Name',
        Footer: 'Name',
        accessor: 'name',
        Filter: ColumnFilter,
    },
    {
        Header: 'Email',
        Footer: 'Email',
        accessor: 'email',
        Filter: ColumnFilter,
    },
    {
        Header: 'Type',
        Footer: 'Type',
        accessor: 'type',
        Filter: ColumnFilter,
    },
    {
        Header: 'created_at',
        Footer: 'created_at',
        accessor: 'created_at',
        Filter: ColumnFilter,
    },
    {
        Header: 'updated_at',
        Footer: 'updated_at',
        accessor: 'updated_at',
        Filter: ColumnFilter,
    },
]

export const auth_keys_col = [
    // {
    //     Header: 'Id',
    //     Footer: 'Id',
    //     accessor: 'id',
    //     Filter: ColumnFilter,
    //     //disableFilters: true,
    // },
    {
        Header: 'Company',
        Footer: 'Company',
        accessor: 'company',
        Filter: ColumnFilter,
    },
    {
        Header: 'API-Key',
        Footer: 'API-Key',
        accessor: 'api_key',
        Filter: ColumnFilter,
    },
    {
        Header: 'Active',
        Footer: 'Active',
        accessor: 'active',
        Filter: ColumnFilter,
        Cell: ({value}) => {
            return value === 0 ? "No" : "Yes"
        }
    },
    {
        Header: 'Created at',
        Footer: 'Created at',
        accessor: 'created_at',
        Filter: ColumnFilter,
    },
]


// received_profiles_col
export const received_profiles_col = [
    {
        Header: 'Email',
        Footer: 'Email',
        accessor: 'email',
        Filter: ColumnFilter,
    },
    {
        Header: 'Created',
        Footer: 'Created',
        accessor: 'created_at',
        Filter: ColumnFilter,
    },
    {
        Header: 'Updated',
        Footer: 'Updated',
        accessor: 'updated_at',
        Filter: ColumnFilter,
    },
    {
        Header: 'Type',
        Footer: 'Type',
        accessor: 'type',
        Cell: ({ value }) => {return getReceivedProfilesType(value)},
        Filter: ColumnFilter,
    },
    {
        Header: 'Id Job (JB)',
        Footer: 'Id Job (JB)',
        accessor: 'job_id',
        Filter: ColumnFilter,
    },
    {
        Header: 'Response status',
        Footer: 'Response status',
        accessor: 'response_status',
        Cell: ({ value }) => {return getCockpitStatus(value)},
        Filter: ColumnFilter,
    },
    {
        Header: 'Response details',
        Footer: 'Response details',
        accessor: 'response_details',
        Cell: ({ value }) => {return getReceivedProfilesErrorMessage(value)},
        Filter: ColumnFilter,
    }
]

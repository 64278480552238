import React, {useContext, useEffect, useState} from 'react';


//Import Components
import {ThemeContext} from "../../config/context/ThemeContext";
import { getNumbersCall} from "../../Api/ApiCalls";
import {toast} from "react-toastify";
import {toastOptions} from "../../config/Constants";
import {hasAValue} from "../../utils/SharedFunctions";
import CountUp from 'react-countup';


const Home = () => {
	const { changeBackground } = useContext(ThemeContext);
	const [totals, setTotals] = useState(undefined)
	useEffect(() => {
		getNumbers()
		changeBackground({ value: "light", label: "Light" });
	}, []);

	return(
		<>
			<div className="row">
				<div className="col-xl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="row">
								<div className="col-md-12 col-sm-12">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<div className="">
													<h2 className="mb-0 font-w600">Welcome at Jopp-admin </h2>
													{/*<p className="mb-0 text-nowrap">New Booking</p>*/}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-4 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<div className="">
													<h2 className="mb-0 font-w500">Employers  {hasAValue(totals) &&  <CountUp className={"bg-white text-primary font-w900"}  end={totals.employers} />} </h2>
													{/*<p className="mb-0 text-nowrap">New Booking</p>*/}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-4 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<div className="">
													<h2 className="mb-0 font-w500">Vacancies  {hasAValue(totals) &&  <CountUp className={"bg-white text-primary font-w900"}  end={totals.vacancies} />} </h2>
													{/*<p className="mb-0 text-nowrap">New Booking</p>*/}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-4 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<div className="">
													<h2 className="mb-0 font-w500">Candidates {hasAValue(totals) && <CountUp className={"bg-white text-primary font-w900"}   end={totals.candidates} />}  </h2>
													{/*<p className="mb-0 text-nowrap">New Booking</p>*/}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-4 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center bg-white">
												<div className="">
													<h2 className="mb-0 font-w500">Applications {hasAValue(totals) && <CountUp className={"bg-white text-primary font-w900"}  end={totals.applications} />} </h2>
													{/*<p className="mb-0 text-nowrap">New Booking</p>*/}
												</div>
											</div>
										</div>
									</div>
								</div>


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)

	function getNumbers() {
		getNumbersCall().then(totals => {
			setTotals(totals)
		}).catch(error => {
			toast.error(error.response.data.error, toastOptions);
		})
	}
}
export default Home;

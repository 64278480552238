import React, {useContext, useEffect, useState} from 'react';
import {getNumbersCall} from "../../Api/ApiCalls";
import {toast} from "react-toastify";
import {toastOptions} from "../../config/Constants";
import {hasAValue} from "../../utils/SharedFunctions";
import CountUp from 'react-countup';
import ApplicationsFromJoppBoardToCockpit
	from "../../components/FromJoppBoardToCockpit/ApplicationsFromJoppBoardToCockpit";
import EmployersFromJoppBoardToCockpit from "../../components/FromJoppBoardToCockpit/EmployersFromJoppBoardToCockpit";
import JobsFromJoppBoardToCockpit from "../../components/FromJoppBoardToCockpit/JobsFromJoppBoardToCockpit";
import CandidatesFromJoppBoardToCockpit from "../../components/FromJoppBoardToCockpit/CandidatesFromJoppBoardToCockpit";


export default function FromJoppBoardToCockpit (){
	const [totals, setTotals] = useState(undefined)
	const [showTotals, setShowTotals] = useState(true)
	const [showTable, setShowTable] = useState(1)
	useEffect(() => {
		getNumbers()
	}, []);

	return(
		<>
			<div className="row">
				<div className="col-xl-12">
					{totals && <div className="row">
						<div className="col-xl-12">
							<div className="row">
								<div className="col-md-6 col-sm-6 cursor-pointer" onClick={()=> showTheRightTable(1)}>
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<div className="w-100">
													<h2 className="mb-0 font-w500">Employers  {hasAValue(totals) &&  <CountUp className={"bg-white text-primary font-w900"}  end={totals.employers.total} />} </h2>
													<div className="card-footer pt-0 pb-0 text-center ">
														<div className="row">
															<div className="col-4 pt-3 pb-3 border-right">
																<h4>Success</h4>
																<h3 className="mb-1 text-primary text-uppercase">{totals.employers.success}</h3>
															</div>
															<div className="col-4 pt-3 pb-3 border-right">
																<h4>New</h4>
																<h3 className="mb-1 text-primary text-uppercase">{totals.employers.new}</h3>
															</div>
															<div className="col-4 pt-3 pb-3">
																<h4>Failed</h4>
																<h3 className="mb-1 text-primary">{totals.employers.failed}</h3>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-6 col-sm-6 cursor-pointer" onClick={()=> showTheRightTable(2)}>
								<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<div className="w-100">
													<h2 className="mb-0 font-w500">Jobs {hasAValue(totals) &&  <CountUp className={"bg-white text-primary font-w900"}  end={totals.vacancies.total} />} </h2>
													<div className="card-footer pt-0 pb-0 text-center ">
														<div className="row">
															<div className="col-4 pt-3 pb-3 border-right">
																<h4>Success</h4>
																<h3 className="mb-1 text-primary text-uppercase">{totals.vacancies.success}</h3>
															</div>
															<div className="col-4 pt-3 pb-3 border-right">
																<h4>New</h4>
																<h3 className="mb-1 text-primary text-uppercase">{totals.vacancies.new}</h3>
															</div>
															<div className="col-4 pt-3 pb-3">
																<h4>Failed</h4>
																<h3 className="mb-1 text-primary">{totals.vacancies.failed}</h3>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-6 col-sm-6 cursor-pointer" onClick={()=> showTheRightTable(3)}>
								<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<div className="w-100">
													<h2 className="mb-0 font-w500">Candidates {hasAValue(totals) && <CountUp className={"bg-white text-primary font-w900"}   end={totals.candidates.total} />}  </h2>
													<div className="card-footer pt-0 pb-0 text-center ">
														<div className="row">
															<div className="col-4 pt-3 pb-3 border-right">
																<h4>Success</h4>
																<h3 className="mb-1 text-primary text-uppercase">{totals.candidates.success}</h3>
															</div>
															<div className="col-4 pt-3 pb-3 border-right">
																<h4>New</h4>
																<h3 className="mb-1 text-primary text-uppercase">{totals.candidates.new}</h3>
															</div>
															<div className="col-4 pt-3 pb-3">
																<h4>Failed</h4>
																<h3 className="mb-1 text-primary">{totals.candidates.failed}</h3>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-6 col-sm-6 cursor-pointer" onClick={()=> showTheRightTable(4)}>
								<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center bg-white">
												<div className="w-100">
													<h2 className="mb-0 font-w500">Applications {hasAValue(totals) && <CountUp className={"bg-white text-primary font-w900"}  end={totals.applications.total} />} </h2>
													<div className="card-footer pt-0 pb-0 text-center ">
														<div className="row">
															<div className="col-4 pt-3 pb-3 border-right">
																<h4>Success</h4>
																<h3 className="mb-1 text-primary text-uppercase">{totals.applications.success}</h3>
															</div>
															<div className="col-4 pt-3 pb-3 border-right">
																<h4>New</h4>
																<h3 className="mb-1 text-primary text-uppercase">{totals.applications.new}</h3>
															</div>
															<div className="col-4 pt-3 pb-3">
																<h4>Failed</h4>
																<h3 className="mb-1 text-primary">{totals.applications.failed}</h3>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>}
					{(!showTotals &&  showTable === 1)&& <EmployersFromJoppBoardToCockpit/>}
					{(!showTotals &&  showTable === 2)&& <JobsFromJoppBoardToCockpit/>}
					{(!showTotals &&  showTable === 3)&& <CandidatesFromJoppBoardToCockpit/>}
					{(!showTotals &&  showTable === 4)&& <ApplicationsFromJoppBoardToCockpit/>}
				</div>
			</div>
		</>
	)

	function showTheRightTable(number){
		setShowTable(number)
		setShowTotals(false)
	}

	function getNumbers() {
		getNumbersCall().then(totals => {
			setTotals(totals)
		}).catch(error => {
			toast.error(error.response.data.error, toastOptions);
		})
	}
}

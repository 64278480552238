import {apiGet, apiPost, apiPut} from "./Api";
import {appUrls} from "../config/Constants";


export async function getAllJobsPublicCall() {
    return apiGet(appUrls.backend_api + "jobs_admin_public").then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function getAllJobsCall() {
    return apiGet(appUrls.backend_api + "jobs_admin").then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function getAllApplicationsCall() {
    return apiGet(appUrls.backend_api + "job_applications_admin").then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function updateApplicationCall(id, data) {
    return apiPut(appUrls.backend_api + `job_applications_admin/${id}`, data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function getAllCandidatesCall() {
    return apiGet(appUrls.backend_api + "job_seekers_admin").then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function getAllEmployersCall() {
    return apiGet(appUrls.backend_api + "employers_admin").then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function step1AuthCall(data) {
    return apiPost(appUrls.backend_api + "request_code", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

//
export async function step2AuthCall(data) {
    return apiPost(appUrls.backend_api + "auth_based_on_code", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function getAllUsersCall() {
    return apiGet(appUrls.backend_api + "users").then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function addNewUserCall(data) {
    return apiPost(appUrls.backend_api + "user", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function updateUserCall(data) {
    return apiPut(appUrls.backend_api + "user", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}


export async function getAllApiKeysCall() {
    return apiGet(appUrls.backend_api + "api_keys").then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function addNewApiKeyCall(data) {
    return apiPost(appUrls.backend_api + "api_key", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}


export async function updateApiKeyCall(data) {
    return apiPut(appUrls.backend_api + "api_key", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function getNumbersCall() {
    return apiGet(appUrls.backend_api + "numbers").then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function uploadFileCall(data) {
    return apiPut(appUrls.backend_api + "upload_file", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function getReceivedProfilesCall() {
    return apiGet(appUrls.backend_api + "received_profiles").then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function updateReceivedProfileCall(id, data) {
    return apiPut(appUrls.backend_api + `received_profiles/${id}`, data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}
export const appUrls = {
    backend_api: process.env.REACT_APP_BACKEND_API

}


export const appLocalStoargeValues = {
    language: 'language',
    user_data: 'user_data',
}


export const appToastMessages = {
    words_data_required: 'You didnt put any words in the fields',
    all_fields_are_required: 'All fields are required',
    notification_has_not_been_sent: 'Notification has not been sent',
    action_canceled: 'Action has been canceled',
    not_paid_order: 'Not paid order',
    done: 'Done',
}


export const toastOptions = {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    limit:5
};

import React, {useEffect, useState} from 'react';
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';
import '../../components/FilteringTable/filtering.css';

import {toast} from "react-toastify";
import {toastOptions} from "../../config/Constants";
import {getAllApplicationsCall, updateApplicationCall} from "../../Api/ApiCalls";
import {applications_col} from "../FilteringTable/Columns";
import {GlobalFilter} from "../FilteringTable/GlobalFilter";
import {Modal} from "react-bootstrap";


export default function ApplicationsFromJoppBoardToCockpit() {
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [id, setId] = useState(0)
    const [content, setContent] = useState({custom_fields: {}});
    const [phoneNumber, setPhoneNumber] = useState('');
    const [location, setLocation] = useState('');
    const [postCode, setPostCode] = useState('');
    const [residence, setResidence] = useState('');
    const [gender, setGender] = useState('');
    const [date, setDate] = useState(undefined);
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        setColumns(applications_col)
        getApplications()
    }, [])


    const tableInstance = useTable({
        columns,
        data,
        initialState: {pageIndex: 0}
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance


    const {globalFilter, pageIndex} = state


    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Applications</h4>
                    {/*<a className="btn btn-secondary" onClick={() => openModalAdd()}>+ Add new domain</a>*/}

                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
                        <table {...getTableProps()} className="table dataTable display">
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span className="ml-1">
                                                {column.isSorted ? (
                                                        column.isSortedDesc ?
                                                            <i className="fa fa-arrow-down ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                            :
                                                            <i className="fa fa-arrow-up ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                    )
                                                    :
                                                    (<i className="fa fa-sort ms-2 fs-14"
                                                        style={{opacity: '0.3'}}/>)}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>

                            {page.map((row) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()}
                                                       onClick={() => openModal(row.original)}
                                            > {cell.render('Cell')} </td>
                                        })}

                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        {/* This is only for footer if u require */}
                        <div className="d-flex justify-content-between">
							<span>
								Page{' '}
                                <strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
                            <span className="table-index">
								Go to page : {' '}
                                <input type="number"
                                       className="ml-2"
                                       defaultValue={pageIndex + 1}
                                       onChange={e => {
                                           const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                           gotoPage(pageNumber)
                                       }}
                                />
							</span>
                        </div>
                        <div className="text-center mb-3">
                            <div className="filter-pagination  mt-3">
                                <button className=" previous-button" onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}>{'<<'}</button>

                                <button className="previous-button" onClick={() => previousPage()}
                                        disabled={!canPreviousPage}>
                                    Previous
                                </button>
                                <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                    Next
                                </button>
                                <button className=" next-button" onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}>{'>>'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal className="modal fade" size={"xl"} show={showModal} onHide={() => setShowModal(false)}>
                <div className="" role="document">
                    <div className="">
                        <form>
                            <div className="modal-header">
                                <h4 className="modal-title fs-20">Job Application</h4>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}
                                        data-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">
                                <i className="flaticon-cancel-12 close"></i>
                                <div className="add-contact-box">
                                    <div className="add-contact-content">
                                        <div className={"row"}>
                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">Id</label>
                                                <div className="contact-name">
                                                    <span className="validation-text">{content.id}</span>
                                                </div>
                                            </div>

                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">Email</label>
                                                <div className="contact-name">
                                                    <span className="validation-text">{content.email}</span>
                                                </div>
                                            </div>

                                            {content.job?.title &&
                                                <div className="form-group mb-3 col-sm-10 col-md-6">
                                                    <label className="text-black font-w500">Job Title</label>
                                                    <div className="contact-name">
                                                        <span className="validation-text">{content.job?.title}</span>
                                                    </div>
                                                </div>}

                                            {content.employer?.name &&
                                                <div className="form-group mb-3 col-sm-10 col-md-6">
                                                    <label className="text-black font-w500">Employer</label>
                                                    <div className="contact-name">
                                                        <span
                                                            className="validation-text">{content.employer?.name}</span>
                                                    </div>
                                                </div>}

                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">Phone Number</label>
                                                <div className="contact-name">
                                                    <input type="text" className="form-control"
                                                           name="phoneNumber"
                                                           value={phoneNumber}
                                                           onChange={(val) => {
                                                               updateContent('phone_number', val.target.value);
                                                               setPhoneNumber(val.target.value);
                                                           }
                                                           }
                                                    />
                                                    <span className="validation-text"></span>
                                                </div>
                                            </div>

                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">Location</label>
                                                <div className="contact-name">
                                                    <input type="text" className="form-control"
                                                           name="location"
                                                           value={location}
                                                           onChange={(val) => {
                                                               updateContent('location', val.target.value);
                                                               setLocation(val.target.value);
                                                           }
                                                           }
                                                    />
                                                    <span className="validation-text"></span>
                                                </div>
                                            </div>

                                            {content.custom_fields['594'] &&
                                                <div className="form-group mb-3 col-sm-10 col-md-6">
                                                    <label
                                                        className="text-black font-w500">{content.custom_fields['594'].name}</label>
                                                    <div className="contact-name">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="postcode"
                                                            value={postCode}
                                                            onChange={(val) => {
                                                                updateCustomField('594', val.target.value);
                                                                setPostCode(val.target.value);
                                                            }
                                                            }
                                                        />
                                                        <span className="validation-text"></span>
                                                    </div>
                                                </div>
                                            }

                                            {content.custom_fields['595'] &&
                                                <div className="form-group mb-3 col-sm-10 col-md-6">
                                                    <label
                                                        className="text-black font-w500">{content.custom_fields['595'].name}</label>
                                                    <div className="contact-name">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="residence"
                                                            value={residence}
                                                            onChange={(val) => {
                                                                updateCustomField('595', val.target.value);
                                                                setResidence(val.target.value);
                                                            }
                                                            }
                                                        />
                                                        <span className="validation-text"></span>
                                                    </div>
                                                </div>
                                            }

                                            {content.custom_fields['593'] &&
                                                <div className="form-group mb-3 col-sm-10 col-md-6">
                                                    <label
                                                        className="text-black font-w500">{content.custom_fields['593'].name}</label>
                                                    <div className="contact-name">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="gender"
                                                            value={gender}
                                                            onChange={(val) => {
                                                                updateCustomField('593', val.target.value);
                                                                setGender(val.target.value);
                                                            }
                                                            }
                                                        />
                                                        <span className="validation-text"></span>
                                                    </div>
                                                </div>
                                            }

                                            {content.custom_fields['258'] &&
                                                <div className="form-group mb-3 col-sm-10 col-md-6">
                                                    <label
                                                        className="text-black font-w500">{content.custom_fields['258'].name}</label>
                                                    <div className="contact-name">
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            name="geboortedatum"
                                                            value={date}
                                                            onChange={
                                                                (val) => {
                                                                    updateCustomField('258', val.target.value);
                                                                    setDate(val.target.value);
                                                                }
                                                            }
                                                        />
                                                        <span className="validation-text"></span>
                                                    </div>
                                                </div>
                                            }

                                            <div className="modal-footer">
                                                <button type="button"
                                                        className="btn btn-secondary"
                                                        onClick={updateApplication}>Update Application
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>

        </>
    )

    function openModal(sourceData) {
        setId(sourceData.id);
        setContent(sourceData.content);
        setPhoneNumber(sourceData.content.phone_number);
        setLocation(sourceData.content.location);
        setPostCode(sourceData.content.custom_fields['594']?.value);
        setResidence(sourceData.content.custom_fields['595']?.value);
        setGender(sourceData.content.custom_fields['593']?.value);
        setDate(getCleanDate(sourceData.content.custom_fields['258']?.value));
        setShowModal(true);
    }

    function getCleanDate(value) {
        if (!value) return;
        return value.split('T')[0];
    }

    function updateCustomField(key, value) {
        content.custom_fields[key].value = value;
        setContent(content);
    }

    function updateContent(field, value) {
        content[field] = value;
        setContent(content);
    }

    function updateApplication() {
        updateApplicationCall(id, {content: JSON.stringify(content)}).then(_ => {
                toast.success('Application updated successfully.', toastOptions);
            }
        ).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        });
    }

    function getApplications() {
        getAllApplicationsCall().then(results => {
            results.map(item => {
                item["email"] = item.content?.email
            })
            setData(results);
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }
}

import React, {useContext, useEffect} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import Home from "../pages/internalPages/Home";
import DashboardDark from "../components/Dashboard/DashboardDark";
import Error400 from "../pages/ErrorPages/Error400";
import Error403 from "../pages/ErrorPages/Error403";
import Error404 from "../pages/ErrorPages/Error404";
import Error500 from "../pages/ErrorPages/Error500";
import Error503 from "../pages/ErrorPages/Error503";
import Login from "../pages/externalPages/auth/Login";
import {hasAValue} from "../utils/SharedFunctions";
import ReceivedProfiles from "../pages/internalPages/ReceivedProfiles";
import VacanciesPublic from "../pages/internalPages/VacanciesPublic";
import Users from "../pages/internalPages/Users";
import {useSelector} from "react-redux";
import ApiKey from "../pages/internalPages/ApiKey";
import FromJoppBoardToCockpit from "../pages/internalPages/FromJoppBoardToCockpit";


export function privatePages() {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const userData = useSelector( state => state.userData );

    return (
        <Switch>

            {/*publicPages*/}
            {!hasAValue(userData) && <Route path='/page-login' component={Login}/>}

            {/*privatePages*/}
            {hasAValue(userData) && <Route path='/dashboard' component={Home}/>}
            {hasAValue(userData) && <Route path='/dashboard-dark' component={DashboardDark}/>}
            {hasAValue(userData) && <Route path='/page-error-400' component={Error400}/>}
            {hasAValue(userData) && <Route path='/page-error-403' component={Error403}/>}
            {hasAValue(userData) && <Route path='/page-error-404' component={Error404}/>}
            {hasAValue(userData) && <Route path='/page-error-500' component={Error500}/>}
            {hasAValue(userData) && <Route path='/page-error-503' component={Error503}/>}
            {hasAValue(userData) && <Route path='/vacancies' component={VacanciesPublic}/>}
            {hasAValue(userData) && <Route path='/received-profiles' component={ReceivedProfiles}/>}
            {hasAValue(userData) && <Route path='/users' component={Users}/>}
            {hasAValue(userData) && <Route path='/from-joppboard-to-cockpit' component={FromJoppBoardToCockpit}/>}
            {(hasAValue(userData) && userData.user_type.toString().includes("admin")) && <Route path='/auth-keys' component={ApiKey}/>}


            {!hasAValue(userData) && <Redirect to="/page-login"/>}
            {hasAValue(userData) && <Redirect to="/dashboard"/>}

        </Switch>
    )
}

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import SimpleReactLightbox from "simple-react-lightbox";
import  ThemeContext  from "./config/context/ThemeContext";
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import {Provider} from "react-redux";


ReactDOM.render(
	<React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
            <SimpleReactLightbox>
                <BrowserRouter basename='/'>
                    <ThemeContext>
                        <App />
                    </ThemeContext>
                </BrowserRouter>
            </SimpleReactLightbox>
            </PersistGate>
        </Provider>
	</React.StrictMode>,
  document.getElementById("root")
);

import React, {useEffect, useState} from 'react';
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';
import '../../components/FilteringTable/filtering.css';

import {toast} from "react-toastify";


import {user_col} from "../../components/FilteringTable/Columns";

import {GlobalFilter} from "../../components/FilteringTable/GlobalFilter";
import {toastOptions} from "../../config/Constants";
import {addNewUserCall, getAllUsersCall, updateUserCall} from "../../Api/ApiCalls";
import {Modal} from "react-bootstrap";


export default function Users() {
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showEdit, setShowEdit] = useState(false)

    const [email, setEmail] = useState(undefined)
    const [name, setName] = useState(undefined)
    const [userType, setUserType] = useState("viewer")
    const [item, setItem] = useState(undefined)



    useEffect(() => {
        setColumns(user_col)
        getAllUsers()
    }, [])


    const tableInstance = useTable({
        columns,
        data,
        initialState: {pageIndex: 0}
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance


    const {globalFilter, pageIndex} = state


    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Users</h4>
                    <a className="btn btn-primary" onClick={() => openModalAdd()}>+ Add new user</a>

                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
                        <table {...getTableProps()} className="table dataTable display">
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span className="ml-1">
                                                {column.isSorted ? (
                                                        column.isSortedDesc ?
                                                            <i className="fa fa-arrow-down ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                            :
                                                            <i className="fa fa-arrow-up ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                    )
                                                    :
                                                    (<i className="fa fa-sort ms-2 fs-14"
                                                        style={{opacity: '0.3'}}/>)}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>

                            {page.map((row) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()}
                                                       className={"cursor-pointer"}  onClick={()=>{ openModalEdit(row.original)}}> {cell.render('Cell')} </td>
                                        })}

                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        {/* This is only for footer if u require */}
                        <div className="d-flex justify-content-between">
							<span>
								Page{' '}
                                <strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
                            <span className="table-index">
								Go to page : {' '}
                                <input type="number"
                                       className="ml-2"
                                       defaultValue={pageIndex + 1}
                                       onChange={e => {
                                           const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                           gotoPage(pageNumber)
                                       }}
                                />
							</span>
                        </div>
                        <div className="text-center mb-3">
                            <div className="filter-pagination  mt-3">
                                <button className=" previous-button" onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}>{'<<'}</button>

                                <button className="previous-button" onClick={() => previousPage()}
                                        disabled={!canPreviousPage}>
                                    Previous
                                </button>
                                <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                    Next
                                </button>
                                <button className=" next-button" onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}>{'>>'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal className="modal fade" size={"xl"} show={showModal} onHide={setShowModal}>
                <div className="" role="document">
                    <div className="">
                        <form>
                            <div className="modal-header">
                                <h4 className="modal-title fs-20">Users</h4>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}
                                        data-dismiss="modal"></button>
                            </div>
                            <div className="modal-body">
                                <i className="flaticon-cancel-12 close"></i>
                                <div className="add-contact-box">
                                    <div className="add-contact-content">
                                        <div className={"row"}>
                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">Name</label>
                                                <div className="contact-name">
                                                    <input type="text" className="form-control"
                                                           name="name" required="required"
                                                           defaultValue={name}
                                                           onChange={(val) => setName(val.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">Email address</label>
                                                <div className="contact-name">
                                                    <input type="text" className="form-control"
                                                           name="email" required="required"
                                                           defaultValue={email}
                                                           onChange={(val) => setEmail(val.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group mb-3 col-sm-10 col-md-6">
                                                <label className="text-black font-w500">User type</label>
                                                <div className="contact-name">
                                                    <select className="form-control"
                                                            name="user_type" required="required"
                                                            defaultValue={userType}
                                                            onChange={(val) => setUserType(val.target.value)}>
                                                        <option value={"viewer"}>Viewer</option>
                                                        <option value={"admin"}>Admin</option>
                                                        <option value={"user"}>User</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {!showEdit && <div className="modal-footer">

                                <button type="button" onClick={() => setShowModal(false)} className="btn btn-danger"><i
                                    className="flaticon-delete-1"></i> Close
                                </button>
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => addNewUser()}>Add
                                </button>
                            </div>}

                            {showEdit && <div className="modal-footer">
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => updateUser()}>Bijwerken
                                </button>
                                <button type="button" onClick={() => setShowModal(false)} className="btn btn-warning"><i
                                    className="flaticon-delete-1"></i> Close
                                </button>
                            </div>}
                        </form>

                    </div>
                </div>
            </Modal>

        </>
    )

    function openModalEdit(item) {
        setUserType(item.type)
        setName(item.name)
        setEmail(item.email)
        setItem(item)

        setShowModal(true)
        setShowEdit(true)
    }

    function openModalAdd() {
        setUserType(undefined)
        setName(undefined)
        setEmail(undefined)


        setShowModal(true)
        setShowEdit(false)
    }


    function addNewUser() {
        let data = {email, name, type:userType}
        addNewUserCall(data).then(success => {
            toast.success(success.message, toastOptions);
            getAllUsers()
            setShowModal(false)
        }).catch(error => {
            console.log(error.response.data)
            toast.error(error.response.data.error, toastOptions);
        })
    }


    function updateUser() {
        let data = {id: item.id, email, name, type: userType}
        updateUserCall(data).then(success => {
            toast.success(success.message, toastOptions);
            getAllUsers()
            setShowModal(false)
        }).catch(error => {
            console.log(error.response.data)
            toast.error(error.response.data.error, toastOptions);
        })
    }

    function getAllUsers() {
        getAllUsersCall().then(applications => {
            setData(applications)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }
}

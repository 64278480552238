import {UPDATE_USER_DATA} from "./actions/storeActions";
import {combineReducers} from "redux";


const userData  = (val = null, action) => {
    switch (action.type) {
        case UPDATE_USER_DATA:
            return action.val
        default:
            return val;
    }
}

export default combineReducers({ userData });
